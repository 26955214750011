<template>
  <div class="page-about">
    <PostDataWrapper postType="page" slug="about">
      <template v-slot="slotProps">
        <Page :page="slotProps.post">
          <template v-slot="pageProps">
            <Banner :image-versions="pageProps.featuredImageVersions">
              <h1>{{ pageProps.title }}</h1>
              <p>{{ pageProps.subHeading }}</p>
            </Banner>

            <PageContainer>
              <div v-html="pageProps.content" class="content"></div>
            </PageContainer>
          </template>
        </Page>
      </template>
    </PostDataWrapper>
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import PageContainer from '@/components/PageContainer'

export default {
  name: 'About',

  mixins: [page],

  components: {
    Banner,
    PageContainer
  }
}
</script>

<style lang="sass">
.page-about
  .content
    img
      width: 100%!important
</style>
