const DEFAULT_HEIGHT = 0
const DEFAULT_WIDTH = 0

let getContentBoxSizeDimensions = function(contentBoxSize) {
  // chrome
  if (contentBoxSize[0]) {
    getContentBoxSizeDimensions = function(contentBoxSize) {
      const size = contentBoxSize[0]
      const width = size.inlineSize
      const height = size.blockSize

      return [width, height]
    }
  } else {
    getContentBoxSizeDimensions = function(contentBoxSize) {
      const width = contentBoxSize.inlineSize
      const height = contentBoxSize.blockSize

      return [width, height]
    }
  }

  return getContentBoxSizeDimensions(contentBoxSize)
}

const modern = {
  setSizer() {
    this.sizer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const contentBoxSize = entry.contentBoxSize

        if (contentBoxSize) {
          const [width, height] = getContentBoxSizeDimensions(contentBoxSize)

          this.width = width
          this.height = height
        }
      })
    })

    this.sizer.observe(this.$el)
  },

  unSetSizer() {
    this.sizer.unobserve(this.$el)
  }
}

export default {
  data() {
    return {
      sizer: null,
      width: DEFAULT_WIDTH,
      height: DEFAULT_HEIGHT
    }
  },

  computed: {
    dimensions() {
      return `${this.width}x${this.height}`
    }
  },

  methods: {
    ...modern,

    getDimensions() {
      this.width = this.$el.offsetWidth
      this.height = this.$el.offsetHeight
    }
  },

  mounted() {
    this.setSizer()

    this.$nextTick(() => {
      this.getDimensions()
    })
  },

  unmounted() {
    this.unSetSizer()
  }
}
