import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=356553d3&scoped=true&"
import script from "./Navigation.vue?vue&type=script&lang=js&"
export * from "./Navigation.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Navigation.vue?vue&type=style&index=0&id=356553d3&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "356553d3",
  "749b4a40"
  
)

export default component.exports