<template>
  <div class="product">
    <h1>{{ title }}</h1>

    <ResponsiveImage
      :image-versions="featuredImageVersions"
      :alt="title"
      :width="width"
      class="image"
    />

    <div v-html="content"></div>

    <div class="product-images" v-if="hasImages">
      <h2>{{ title }} images</h2>

      <Carousel
        :navigationEnabled="true"
        :perPage="1"
        :perPageCustom="[
          [600, 2],
          [800, 3],
          [1000, 4]
        ]"
        :paginationEnabled="false"
        class="product-carousel"
      >
        <Slide
          v-for="image in images"
          :key="image.id"
          class="product-carousel-item"
          @click.native="selectImage(image)"
        >
          <LazyImg class="image" :url="image.url" :description="title" />
        </Slide>
      </Carousel>

      <Overlay :active="!!selectedImage" @close="unselectImage">
        <LazyImg
          v-if="selectedImage"
          class="selected-image"
          :url="selectedImage.url"
          :description="title"
        />
      </Overlay>
    </div>
  </div>
</template>

<script>
import post from '@/mixins/post'
import sizable from '@/mixins/sizable'

import ResponsiveImage from '@/components/ResponsiveImage'
import {Carousel, Slide} from '@/lib/vue-carousel'
import LazyImg from '@/components/LazyImg'
import Overlay from '@/components/Overlay'

export default {
  name: 'Product',

  mixins: [post, sizable],
  data() {
    return {
      selectedImage: null
    }
  },

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  components: {
    ResponsiveImage,
    LazyImg,
    Carousel,
    Slide,
    Overlay
  },

  computed: {
    post() {
      return this.product
    },

    images() {
      if (!this.acf.images) {
        return []
      }

      return this.acf.images.map((image) => {
        return {
          id: image.ID,
          url: image.guid
        }
      })
    },

    hasImages() {
      return !!this.images.length
    }
  },

  methods: {
    selectImage(image) {
      this.selectedImage = image
    },

    unselectImage() {
      this.selectedImage = null
    }
  }
}
</script>

<style lang="sass" scoped>
.product
  .image
    max-width: 800px
    margin: auto
    margin-top: 60px
    margin-bottom: 60px

  .product-images
    margin-top: 60px

    h2
      margin-bottom: 30px

    .product-carousel
      .product-carousel-item
        margin: 0
        padding: 10px
        cursor: pointer

        .image
          aspect-ratio: 1/1

  .selected-image
    max-width: 100%
</style>
