import dataWrapper from '@/mixins/data_wrapper'

import {decodeHtml} from '@/filters'

const LOADING_TEXT = 'Loading...'

export default {
  mixins: [dataWrapper],

  computed: {
    content() {
      const content = this.post.content

      return content ? content.rendered : LOADING_TEXT
    },

    slug() {
      return this.post.slug
    },

    postType() {
      return this.post.type
    },

    acf() {
      return this.post.acf || {}
    },

    title() {
      // Because sometimes its a taxonomy not a post :(
      const title = this.post.title ? this.post.title.rendered : this.post.name

      return title ? decodeHtml(title) : LOADING_TEXT
    },

    excerpt() {
      return this.post.excerpt ? this.post.excerpt.rendered : LOADING_TEXT
    },

    subHeading() {
      return this.acf.sub_heading ? this.acf.sub_heading : LOADING_TEXT
    },

    readAt() {
      return this.resourceReadAt(this.post)
    },

    postStale() {
      return this.resourceStale(this.readAt)
    },

    embedded() {
      return this.post._embedded || {}
    },

    featuredMedia() {
      return this.embedded['wp:featuredmedia'] || {}
    },

    featuredImageMediaDetails() {
      return this.featuredImage.media_details || {}
    },

    featuredImageSizes() {
      return this.featuredImageMediaDetails.sizes || {}
    },

    featuredImageVersions() {
      return Object.keys(this.featuredImageSizes)
        .map((key) => {
          const size = this.featuredImageSizes[key]

          return {
            width_px: size.width,
            url: size.source_url,
            name: key
          }
        })
        .sort((a, b) => {
          if (a.width_px < b.width_px) {
            return -1
          }

          if (a.width_px > b.width_px) {
            return 1
          }

          return 0
        })
    },

    featuredImage() {
      return this.featuredMedia['0'] || {}
    },

    featuredImageUrl() {
      // `acf.image` for category images
      return this.featuredImage.source_url || this.acf.image || null
    }
  }
}
