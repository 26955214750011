import Vue from 'vue'

import * as types from './types'

const READ_AT_KEY = '__read_at'

function loading(state) {
  let count = state.activeRequests + 1
  Vue.set(state, 'activeRequests', count)
}

function loaded(state) {
  let count = state.activeRequests - 1
  Vue.set(state, 'activeRequests', count)
}

export default {
  /**
   * Advice mutations
   */
  [types.REQUEST_ADVICE](state) {
    loading(state)
  },

  [types.RECEIVE_ADVICE](state, {slug, advice, readAt}) {
    advice[READ_AT_KEY] = readAt

    let list = state.adviceList.filter((article) => {
      return article.id !== advice.id
    })

    list.push(advice)

    Vue.set(state.adviceBySlug, slug, advice)
    Vue.set(state, 'adviceList', list)
  },

  [types.REQUESTED_ADVICE](state) {
    loaded(state)
  },

  [types.REQUEST_ADVICE_LIST](state) {
    loading(state)
  },

  [types.RECEIVE_ADVICE_LIST](state, {advice, readAt}) {
    Vue.set(state, 'adviceListedAt', readAt)

    advice = advice.map((article) => {
      article[READ_AT_KEY] = readAt
      return article
    })

    Vue.set(state, 'adviceList', advice)

    advice.forEach((article) => {
      Vue.set(state.adviceBySlug, article.slug, article)
    })
  },

  [types.REQUESTED_ADVICE_LIST](state) {
    loaded(state)
  },

  [types.REQUEST_FEATURED_ADVICE_LIST](state) {
    loading(state)
  },

  [types.RECEIVE_FEATURED_ADVICE_LIST](state, {advice, readAt}) {
    Vue.set(state, 'featuredAdviceListedAt', readAt)

    advice = advice.map((article) => {
      article[READ_AT_KEY] = readAt
      return article
    })

    Vue.set(state, 'featuredAdviceList', advice)

    advice.forEach((article) => {
      Vue.set(state.adviceBySlug, article.slug, article)
    })
  },

  [types.REQUESTED_FEATURED_ADVICE_LIST](state) {
    loaded(state)
  },

  /**
   * Affiliation mutations
   */
  [types.REQUEST_AFFILIATION_LIST](state) {
    loading(state)
  },

  [types.RECEIVE_AFFILIATION_LIST](state, {affiliations, readAt}) {
    Vue.set(state, 'affiliationsListedAt', readAt)

    affiliations = affiliations.map((article) => {
      article[READ_AT_KEY] = readAt
      return article
    })

    Vue.set(state, 'affiliationList', affiliations)
  },

  [types.REQUESTED_AFFILIATION_LIST](state) {
    loaded(state)
  },

  /**
   * Package mutations
   */
  [types.REQUEST_PACKAGE](state) {
    loading(state)
  },

  [types.RECEIVE_PACKAGE](state, {slug, pack, readAt}) {
    pack[READ_AT_KEY] = readAt

    let list = state.packageList.filter((article) => {
      return article.id !== pack.id
    })

    list.push(pack)

    Vue.set(state.packagesBySlug, slug, pack)
    Vue.set(state, 'packageList', list)
  },

  [types.REQUESTED_PACKAGE](state) {
    loaded(state)
  },

  [types.REQUEST_PACKAGE_LIST](state) {
    loading(state)
  },

  [types.RECEIVE_PACKAGE_LIST](state, {packages, readAt}) {
    Vue.set(state, 'packagesListedAt', readAt)

    packages = packages.map((article) => {
      article[READ_AT_KEY] = readAt
      return article
    })

    Vue.set(state, 'packageList', packages)

    packages.forEach((article) => {
      Vue.set(state.packagesBySlug, article.slug, article)
    })
  },

  [types.REQUESTED_PACKAGE_LIST](state) {
    loaded(state)
  },

  /**
   * Menu mutations
   */
  [types.REQUEST_MENU](state) {
    loading(state)
  },

  [types.RECEIVE_MENU](state, {slug, menu, readAt}) {
    menu[READ_AT_KEY] = readAt
    Vue.set(state.menusBySlug, slug, menu)
  },

  [types.REQUESTED_MENU](state) {
    loaded(state)
  },

  /**
   * Page mutations
   */
  [types.REQUEST_PAGE](state) {
    loading(state)
  },

  [types.RECEIVE_PAGE](state, {slug, page, readAt}) {
    page[READ_AT_KEY] = readAt
    Vue.set(state.pagesBySlug, slug, page)
  },

  [types.REQUESTED_PAGE](state) {
    loaded(state)
  },

  /**
   * Service mutations
   */
  [types.REQUEST_SERVICE](state) {
    loading(state)
  },

  [types.RECEIVE_SERVICE](state, {slug, service, readAt}) {
    service[READ_AT_KEY] = readAt

    let list = state.serviceList.filter((article) => {
      return article.id !== service.id
    })

    list.push(service)

    Vue.set(state.servicesBySlug, slug, service)
    Vue.set(state, 'serviceList', list)
  },

  [types.REQUESTED_SERVICE](state) {
    loaded(state)
  },

  [types.REQUEST_SERVICE_LIST](state) {
    loading(state)
  },

  [types.RECEIVE_SERVICE_LIST](state, {services, readAt}) {
    Vue.set(state, 'servicesListedAt', readAt)

    services = services.map((article) => {
      article[READ_AT_KEY] = readAt
      return article
    })

    Vue.set(state, 'serviceList', services)

    services.forEach((article) => {
      Vue.set(state.servicesBySlug, article.slug, article)
    })
  },

  [types.REQUESTED_SERVICE_LIST](state) {
    loaded(state)
  },

  [types.REQUEST_FEATURED_SERVICE_LIST](state) {
    loading(state)
  },

  [types.RECEIVE_FEATURED_SERVICE_LIST](state, {services, readAt}) {
    Vue.set(state, 'featuredServicesListedAt', readAt)

    services = services.map((article) => {
      article[READ_AT_KEY] = readAt
      return article
    })

    Vue.set(state, 'featuredServiceList', services)

    services.forEach((article) => {
      Vue.set(state.servicesBySlug, article.slug, article)
    })
  },

  [types.REQUESTED_FEATURED_SERVICE_LIST](state) {
    loaded(state)
  },

  /**
   * Tag mutations
   */
  [types.REQUEST_TAG_LIST](state) {
    loading(state)
  },

  [types.RECEIVE_TAG_LIST](state, {tags, readAt}) {
    Vue.set(state, 'tagsListedAt', readAt)

    tags.forEach((article) => {
      Vue.set(state.tagsBySlug, article.slug, article)
    })
  },

  [types.REQUESTED_TAG_LIST](state) {
    loaded(state)
  },

  /**
   * Location service mutations
   */
  [types.REQUEST_LOCATION_SERVICE_LIST](state) {
    loading(state)
  },

  [types.RECEIVE_LOCATION_SERVICE_LIST](state, {services, readAt}) {
    Vue.set(state, 'locationServicesListedAt', readAt)

    Vue.set(state, 'locationServiceList', services)
  },

  [types.REQUESTED_LOCATION_SERVICE_LIST](state) {
    loaded(state)
  },

  /**
   * Testimonial mutations
   */
  [types.REQUEST_TESTIMONIAL_LIST](state) {
    loading(state)
  },

  [types.RECEIVE_TESTIMONIAL_LIST](state, {testimonials, readAt}) {
    Vue.set(state, 'testimonialsListedAt', readAt)

    testimonials = testimonials.map((article) => {
      article[READ_AT_KEY] = readAt
      return article
    })

    Vue.set(state, 'testimonialList', testimonials)
  },

  [types.REQUESTED_TESTIMONIAL_LIST](state) {
    loaded(state)
  },

  /**
   * Locations mutations
   */
  [types.REQUEST_LOCATION_LIST](state) {
    loading(state)
  },

  [types.RECEIVE_LOCATION_LIST](state, {locations, readAt}) {
    Vue.set(state, 'locationsListedAt', readAt)

    locations = locations.map((location) => {
      location[READ_AT_KEY] = readAt
      return location
    })

    Vue.set(state, 'locationList', locations)
  },

  [types.REQUESTED_LOCATION_LIST](state) {
    loaded(state)
  },

  /**
   * Category mutations
   */

  [types.REQUEST_CATEGORY_LIST](state) {
    loading(state)
  },

  [types.RECEIVE_CATEGORY_LIST](state, {categories, readAt}) {
    Vue.set(state, 'categoriesListedAt', readAt)

    categories = categories.map((category) => {
      category[READ_AT_KEY] = readAt
      return category
    })

    categories.forEach((category) => {
      Vue.set(state.categoriesBySlug, category.slug, category)
    })

    Vue.set(state, 'categoryList', categories)
  },

  [types.REQUESTED_CATEGORY_LIST](state) {
    loaded(state)
  },

  /**
   * Product mutations
   */

  [types.REQUEST_PRODUCT_LIST](state) {
    loading(state)
  },

  [types.RECEIVE_PRODUCT_LIST](state, {products, readAt}) {
    Vue.set(state, 'productsListedAt', readAt)

    products = products.map((product) => {
      product[READ_AT_KEY] = readAt
      return product
    })

    products.forEach((product) => {
      Vue.set(state.productsBySlug, product.slug, product)
    })

    Vue.set(state, 'productList', products)
  },

  [types.REQUESTED_PRODUCT_LIST](state) {
    loaded(state)
  },

  [types.REQUEST_PRODUCT](state) {
    loading(state)
  },

  [types.REQUESTED_PRODUCT](state) {
    loaded(state)
  },

  [types.RECEIVE_PRODUCT](state, {product, readAt}) {
    product[READ_AT_KEY] = readAt

    Vue.set(state.productsBySlug, product.slug, product)
  },

  [types.REQUEST_MESSAGE_SUBJECT_LIST](state) {
    loading(state)
  },

  [types.RECEIVE_MESSAGE_SUBJECT_LIST](state, {messageSubjects, readAt}) {
    Vue.set(state, 'messageSubjectsListedAt', readAt)

    messageSubjects = messageSubjects.map((article) => {
      article[READ_AT_KEY] = readAt
      return article
    })

    Vue.set(state, 'messageSubjectList', messageSubjects)
  },

  [types.REQUESTED_MESSAGE_SUBJECT_LIST](state) {
    loaded(state)
  }
}
