import { render, staticRenderFns } from "./Slide.vue?vue&type=template&id=34c4bffb&"
import script from "./Slide.vue?vue&type=script&lang=js&"
export * from "./Slide.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Slide.vue?vue&type=style&index=0&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "286d9715"
  
)

export default component.exports