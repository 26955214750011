<template>
  <div class="page-page">
    <PostDataWrapper postType="page" :slug="wpSlug">
      <template v-slot="slotProps">
        <Page :page="slotProps.post">
          <template v-slot="pageProps">
            <Banner :image-versions="pageProps.featuredImageVersions">
              <h1>{{ pageProps.title }}</h1>
              <p>{{ pageProps.subHeading }}</p>
            </Banner>

            <PageContainer class="content">
              <div v-html="pageProps.content" class="content"></div>
            </PageContainer>
          </template>
        </Page>
      </template>
    </PostDataWrapper>
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import PageContainer from '@/components/PageContainer'

export default {
  name: 'PagePage',

  mixins: [page],

  components: {
    Banner,
    PageContainer
  },

  computed: {
    wpSlug() {
      return this.$route.meta.wpSlug
    }
  }
}
</script>

<style lang="sass">
@import "~@/stylesheets/base/variables"
@import "~@/stylesheets/helpers/mixins"

.page-page
  .contained
  .content
    img
      width: 100%
    h2
      margin-top: 22px

    h3

    img
      width: 100%!important

    table
      margin-bottom: $pad*2
      border-collapse: collapse
      width: 100%

      @media print
        margin-bottom: $pad

      tr
        @media print
          page-break-inside: avoid!important

        &:nth-of-type(2n)
          background: $color-light-background

        td
          padding: 10px
          border: 1px solid $color-text-light

          @media print
            padding: 2px

    .pdf-link
      display: inline-block
      background: $color-highlight
      color: $color-light
      text-decoration: none
      cursor: pointer
      text-transform: uppercase
      font-size: $font-size-small
      letter-spacing: 1.2px
      font-family: $font-main
      padding: $pad / 2
      margin: $pad/2 0

      @media print
        display: none !important

      @include media($media-gt-big)
        transition: padding $transition-duration $transition-easing, font-size $transition-duration $transition-easing

      @include media($media-gt-very-big)
        padding: $pad
</style>
